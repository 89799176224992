.global-wrapper-inner {
  @media (max-width: @screen-xs-max) {
    .container {
      padding-left: 10px;
      padding-right: 10px;

    }

    .row {
      margin-left: -10px;
      margin-right: -10px;

      > [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
