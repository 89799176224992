.header-top {
  position: relative;
  img {
    width: 100%;
  }
}
#header-menu {
  position: absolute;
  right: 7px;
  top: 0;

  li {
    float: left !important;
    a {
      color: #777;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; // remove the gap so it doesn't close
}
.navbar-brand {
  img {
    max-height: 28px
  }
}

.global-wrapper {
  margin: 0 auto;
}

.favourite-link {
  &:before{
    content: url("/img/favorite.gif");
  }
}

.footer {
}