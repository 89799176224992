@media (max-width: 767px) {
  .global-wrapper-inner .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .global-wrapper-inner .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .global-wrapper-inner .row > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.display-table {
  display: table;
  width: 100%;
  height: 100%;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header-top {
  position: relative;
}
.header-top img {
  width: 100%;
}
#header-menu {
  position: absolute;
  right: 7px;
  top: 0;
}
#header-menu li {
  float: left !important;
}
#header-menu li a {
  color: #777;
  padding-top: 5px;
  padding-bottom: 5px;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.navbar-brand img {
  max-height: 28px;
}
.global-wrapper {
  margin: 0 auto;
}
.favourite-link:before {
  content: url("/img/favorite.gif");
}
/*
@import "@{view-path}/design/carousel/index";
@import "@{view-path}/design/carousel_item/index";
@import "@{view-path}/design/content_text/index";
@import "@{view-path}/design/page/index";
@import "@{view-path}/design/section/index";
@import "@{view-path}/design/teaser/index";
@import "@{view-path}/design/video_section/index";

@import "@{view-path}/site/index";
*/
.navbar-brand {
  padding: 11px 15px;
}
.site-login {
  padding: 16px;
  background-image: url("/img/login-bg.gif");
}
.site-login h4 {
  margin-top: 0;
}
.signup-text {
  color: #666666;
}
.site-edit-profile {
  padding: 16px;
  background-image: url("/img/login-bg.gif");
  color: #666666;
}
.site-edit-profile button {
  float: right;
  margin-right: 20px;
}
.site-edit-profile .profile-form-label {
  text-align: left;
}
.site-edit-profile h3 {
  margin-bottom: 20px;
}
.site-edit-profile .form-group {
  margin-bottom: 1px;
}
.site-edit-profile .field-userprofile-gender div {
  width: 110px;
}
.site-signup,
.site-reset-password,
.site-request-password-reset {
  padding: 16px;
  background-image: url(/img/login-bg.gif);
  color: #666666;
}
.site-signup .form-group,
.site-reset-password .form-group,
.site-request-password-reset .form-group {
  margin-bottom: 1px;
}
.site-signup .signup-form-label,
.site-reset-password .signup-form-label,
.site-request-password-reset .signup-form-label {
  text-align: left;
}
.site-signup .field-signupform-gender div,
.site-reset-password .field-signupform-gender div,
.site-request-password-reset .field-signupform-gender div {
  width: 110px;
}
.site-signup .signup-button,
.site-reset-password .signup-button,
.site-request-password-reset .signup-button {
  float: right;
  margin-right: 20px;
}
.sidebar-nav .nav > li {
  float: none;
}
.sidebar-nav .nav > li > a {
  padding-top: 5px;
  padding-bottom: 5px;
}
#module-category-view .modules .module {
  padding: 16px;
  margin-bottom: 20px;
  background-image: url("/img/login-bg.gif");
}
#training .panel-body {
  padding: 30px;
}
#training .question-text {
  color: #1a3771;
  font-size: 14px;
}
#training .answers {
  margin-bottom: 60px;
  padding-left: 25px;
}
#training .answers .answer {
  display: flex;
  font-weight: 500;
}
#training .answers .answer input {
  margin-right: 5px;
}
#training .answers .hint {
  padding: 6px;
  border: 1px solid #1a3771;
  background-color: #FFF;
  margin-left: 20px;
}
#training .answers .hint p {
  margin-bottom: 0;
}
#training .answers .answer-text {
  display: inline-block;
}
#training .answers .answer-text p {
  margin-bottom: 0;
}
.certificate-content-wrapper {
  background-image: url("/img/login-bg.gif");
}
.certificate-item {
  margin-bottom: 10px;
}
.certificate-item .test-passed-mark {
  display: inline-block;
  height: 23px;
  padding: 0;
  width: 23px;
  text-align: center;
  color: #fff;
  background-color: #45c287;
  border: 1px solid #3bb37a;
  padding-top: 2px;
}
.certificate-group h4 {
  font-weight: 700;
}
.certificate-group hr {
  margin-top: 0;
}
.certificate-title h4 {
  line-height: 50px;
}
.certificate-title p {
  text-align: center;
}
.profile-content-wrapper {
  padding-top: 20px;
  padding-left: 20px;
  background-image: url("/img/login-bg.gif");
}
.imprint-content .imprint-row {
  margin-bottom: 10px;
}
.imprint-content .imprint-row p {
  margin-bottom: 5px;
}
.teaser {
  margin-bottom: 20px;
}
.teaser .teaser-header {
  border-bottom: 1px solid #777777;
}
.teaser .teaser-content {
  padding: 15px;
}
